//SIDEBAR
.information-column{
	.site-affiliation{
		font-weight: 700;
	}

	//Social Media Sidebar
	
	.sidebar_social-media{
		text-align: center;
	    display: inline-block;
	    width: 100%;
	    height: 75px;
	    padding: 0% 43%;

	    @include breakpoint ($md){
	    	padding: 0% 25%;
	    }

	    @include breakpoint ($lg){
	    	padding: 0% 30%;
	    }
	    
		.wcm-cta__tile{
			border: none;
			width: 50px;
		    height: 50px;
		    padding: 10px;
		    text-align: center;

			.cta-icon {
			    width: 30px;
			    height: 30px;
		    }
		}
	}
	
}

/* =Default Select Elements
----------------------------------------------------------*/

.form-select {
	max-width: none;
	height: 50px;
	border: 1px solid $wcm-border-gray;
	box-shadow: none;
	appearance: none;
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
	  background-repeat: no-repeat;
	  background-position: right 15px top 50%;
	background-size: 20px 20px;
	padding-right: 50px;
}

//FILE ICON OVERRIDE
.file-icon{
	display: none;
}

@mixin icon_override(){
	background-position: center left;
	background-repeat: no-repeat;
	padding: 5px 0px 5px 35px;
	//object-fit: margin;
	background-size: 25px;
}

a[href*=".pdf"]{
	@include icon_override();
	background-image: url('/sites/all/themes/wcm_wcchr_subtheme/images/wcmc_field_icons/application-pdf.png');
}

a[href*=".doc"]{
	@include icon_override();
	background-image: url('/sites/all/themes/wcm_wcchr_subtheme/images/wcmc_field_icons/x-office-document.png');
}

a[href*=".docx"]{
	@include icon_override();
	background-image: url('/sites/all/themes/wcm_wcchr_subtheme/images/wcmc_field_icons/x-office-document.png');
}

a[href*=".xls"]{
	@include icon_override();
	background-image: url('/sites/all/themes/wcm_wcchr_subtheme/images/wcmc_field_icons/x-office-spreadsheet.png');
}

a[href*=".xlsx"]{
	@include icon_override();
	background-image: url('/sites/all/themes/wcm_wcchr_subtheme/images/wcmc_field_icons/x-office-spreadsheet.png');
}

a[href*=".ppt"]{
	@include icon_override();
	background-image: url('/sites/all/themes/wcm_wcchr_subtheme/images/wcmc_field_icons/x-office-presentation.png');
}

a[href*=".pptx"]{
	@include icon_override();
	background-image: url('/sites/all/themes/wcm_wcchr_subtheme/images/wcmc_field_icons/x-office-presentation.png');
}