.not-front{

	//NEWS LIST VIEW
    .view-press{
        
        .select2-container{
            width: 400px !important;
        }

        .views-row{
            padding: 0px 0px 40px 0px;
            margin: 40px 0px 0px 0px;
            border-bottom: solid 1px #ddd;
            .press-image{
                img{
                    float: right;
                    margin: 0px 10px;
                }
            }
            h3.teaser-title{
                margin: 0px;
            }

            .views-field-field-event-date{
	        	text-transform: uppercase;
	        	margin: 10px 0 5px;
			    color: $wcm-med-gray;
			    font-size: 13px;
			    font-weight: 700;
			    letter-spacing: 2px;
			    
	        }

            a.read-more{
                padding: 20px 0px 0px 0px;
                display: inline-block;
            }
        }

        .views-row-last{
            border-bottom: none;
        }
    }

    //Press Individual Page
    .node-type-press{
        h3.pane-title{
            float: left;
        }

        .field-name-field-news-category{
            float: left;
            margin: 20px 0 0;
        }
    }

    //Events
    //Events disabled
    /*
    
    .view-events{
        
        .views-row{
            padding: 0px 0px 40px 0px;
            margin: 40px 0px 0px 0px;
            border-bottom: solid 1px #ddd;
            .teaser-image{
                img{
                    float: right;
                    margin: 0px 10px;
                }
            }

            h3.teaser-title{
                margin: 0px;
            }

            a.read-more{
                padding: 20px 0px 0px 0px;
                display: inline-block;
            }
        }

        .views-row-last{
            border-bottom: none;
        }
    }
    */


    //RESOURCES
    .view-resource-centers{
        h5{
            margin-top: 20px;
            margin-bottom: 10px;
        }
        .views-row{
        display: inline-block;
        width: 100%;
            .views-label{
                font-weight: bold;
                float: left;
                padding-right: 5px;
            }
            .views-field-title{
                font-weight: bold;
            }
        }
    }

    //HEADSHOTS
    .headshot-grid{
        padding-top: 20px;
        .headshot-entry{
            height: 300px;
        }
    }
}