/*LEVEL 1 DRAWER NAV OVERRIDE*/
#primary-nav .level-1 {
  @include breakpoint ($sm){
        width: 15%;
        padding: 8px 0px;
        &:nth-child(5){
            width: 25%;
        }
    }

    @include breakpoint ($md){
        width: 15%;
        padding: 8px 0px;
        &:nth-child(5){
            width: 23%;
        }
    }
}

#drawer-nav .level-2 {
	@include breakpoint ($sm){
		padding: 8px 0px;
        width: 50%;
    }
        @include breakpoint ($md){
        padding: 8px 0px;
        width: 33%;
    }

    @include breakpoint ($lg){
    	padding: 8px 0px;
        width: 33%;
    }
}


/*FOOTER NAV*/
.block-menu-block .content {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px;
}
