.front{	
	.homepage-hero_image{
		background: url(/sites/default/files/styles/panopoly_image_original/public/homepage_hero.jpg) no-repeat scroll center center;
		background-size: cover;
		margin: 0px;
		padding: 0px;
		
		@include breakpoint ($xs){
		    height: auto;
		}
		
		.homepage-hero_overlay{
			background-color: #fff;
			opacity: 0.9;
			padding: 0px;
			margin-bottom: -5px;

			@include breakpoint ($xs){
			    height: auto;
			}

		    .homepage-hero-cta_text{
		    	display: inline-block;
		    	padding: 45px 26% 10px 26%;
		    	text-align: center;
				font-weight: bold;
			    
			    @include breakpoint ($xs){
			    	padding: 10px;
			    }
			}
			
			.homepage-hero-cta_container{
				background-color: $wcm-red;
				display: block;
				overflow: hidden;
				margin-bottom: 70px;

				@include breakpoint ($xs){
					margin-bottom: 30px;
				    margin-left: 20px;
					margin-right: 20px;
				}

				.col-sm-4{
					padding: 0px !important;

					.homepage-hero-cta_left, .homepage-hero-cta_center, .homepage-hero-cta_right{
						color: white;
						text-align: center;
						padding: 15px 12% 0px 12%;

						@include breakpoint ($xs){
							padding-left: 0px;
							padding-right: 0px;
						}

						&:hover{
							background-color: $wcm-dark-orange;
						}

						h3.pane-title{
							color: white;
							padding-bottom: 5px;
						}

						p:last-child{
							margin: 0px;

							@include breakpoint ($xs){
								padding-left: 20px;
								padding-right: 20px;
							}

						    @include breakpoint ($sm){
						    	min-height: 100px;
						    }

						    @include breakpoint ($md){
						    	min-height: 75px;
						    }

						    @include breakpoint ($lg){
						    	min-height: 75px;
						    }
						}

						&:after{
					        content: '\f105';
						    font-family: "fontello";
						    display: block;
						    border-top: solid 1px #fff;
						    margin: 15px 25% 0px 25%;
						    font-size: 25px;
						    text-align: center;

						    @include breakpoint ($xs){
						    	margin: 15px 30% 0px 30%;
						    }
						}
					}

					.homepage-hero-cta_center{
						border-left: solid 1px $wcm-white;
						border-right: solid 1px $wcm-white;
						
						@include breakpoint ($xs){
							border-top: solid 1px $wcm-white;
							border-right: 0px;
							border-bottom: solid 1px $wcm-white;
							border-left: 0px;
						}
					}
				}
			}
		}
	}

	//Map
	.homepage_map{

		//Map disabled at mobile	
		@include breakpoint ($xs){
			display: none;
		}


		h1.pane-title{
			text-align: center;
		    margin: 50px 0 0;
		}
	}

	//Map Legend
	.homepage_legend{

		//Map Legend disabled at mobile	
		@include breakpoint ($xs){
			display: none;
		}

		h3.pane-title{
			text-align: center;
			position: relative;  
			width: 100%;
			margin: 0px;
			position: relative;
		    z-index: 1;
		    overflow: hidden;
		    
		    @mixin horizontal-lines(){
		    	position: absolute;
			    top: 51%;
			    overflow: hidden;
			    width: 50%;
			    height: 1px;
			    content: '\a0';
			    background-color: $wcm-border-gray;
		    }

			&:before {
				@include horizontal-lines;
			    margin-left: -52%;
			    text-align: right;
			}

			&:after {
				@include horizontal-lines;
				margin-left: 2%;
			}
		}

		//Table Override
		table{
			border: 0px;
	        margin-left: 20px;

			tbody{
				border: 0px;
				
				td{
					border: 0px;
					background: none;
		            width: 40px;
		            padding: 0px 8px;

					&:odd{
						font-weight: bold;
					}
				}
			}
		}

		.table-striped>tbody>tr>td:nth-child(even){
			font-weight: bold;
		}
	}

	//Events
	.homepage_events_image{
		padding-top: 20px;

		@include breakpoint ($xs){
		    margin-bottom: -2px;
		}

		@include breakpoint ($sm){
		    margin-bottom: -2px;
		}

		.events-hero-image{
			height: 225px;
			overflow: hidden;

			@include breakpoint ($xs){
				height: auto;
			}

			img.panopoly-image-full{
				@include breakpoint ($xs){
				    margin-bottom: 0px;
				}

				@include breakpoint ($sm){
				    margin-bottom: 0px;
				}
			}
		}
	}

	.homepage_events{
		@include breakpoint ($xs){
			padding-top: 0px;
		}

		@include breakpoint ($sm){
			padding-top: 0px;
		}

		@include breakpoint ($md){
			padding-top: 20px;
		}

		@include breakpoint ($lg){
			padding-top: 20px;
		}

		.events-hero-text{
			background-color: $wcm-red;
			height: 225px;
			color: $wcm-white;
			text-align: center;
			padding: 35px 35px 20px 35px;

			@include breakpoint ($xs){
				height: auto;
				padding: 35px 20px 20px 20px;
			}

			&:hover{
				background-color: $wcm-dark-orange;
			}

			h3.pane-title{
				color: $wcm-white;
				margin: 0px;		
			}

			p:last-child{

				&:after{
			        content: '\f105';
				    font-family: "fontello";
				    display: block;
				    border-top: solid 1px #fff;
				    margin: 15px 25% 0px 25%;
				    font-size: 25px;
				    text-align: center;

				    @include breakpoint ($xs){
				    	margin: 15px 40% 0px 40%;
				    }
				}
			}
		}
	}
}  